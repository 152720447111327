<template>
    <div class="container py-5">
      <h1 class="text-center mb-5">选择要安装的软件和版本</h1>
      <div class="row row-cols-1 row-cols-md-2 g-4">
        <div class="col" v-for="app in apps" :key="app.id">
          <div class="card h-100 shadow-sm position-relative">
            <div class="card-body d-flex flex-column">
              <div class="text-center mb-3">
                <img
                  :src="app.iconSrc"
                  :alt="app.name"
                  class="img-fluid mb-3"
                  style="width: 100px; height: 100px; object-fit: cover;"
                />
                <h5 class="card-title">{{ app.name }}</h5>
                <div class="product-notes" v-if="app.note">{{ app.note }}</div>
              </div>
              <p class="card-text flex-grow-1">{{ app.description }}</p>
              <button @click="installApp(app)" class="btn btn-primary mt-3">
                安装
              </button>
            </div>
          </div>
        </div>
      </div>
  
      <div class="text-center mt-5">
        <button class="btn btn-secondary" @click="toggleAlternativeApps">
          {{ showAlternativeApps ? '收起备选项' : '展开备选项' }}
        </button>
      </div>
  
      <div v-if="showAlternativeApps" class="mt-4">
        <div class="row row-cols-1 row-cols-md-2 g-4">
          <div class="col" v-for="app in alternativeApps" :key="app.id">
            <div class="card h-100 shadow-sm position-relative">
              <div class="card-body d-flex flex-column">
                <div class="text-center mb-3">
                  <img
                    :src="app.iconSrc"
                    :alt="app.name"
                    class="img-fluid mb-3"
                    style="width: 100px; height: 100px; object-fit: cover;"
                  />
                  <h5 class="card-title">{{ app.name }}</h5>
                  <div class="product-notes" v-if="app.note">{{ app.note }}</div>
                </div>
                <p class="card-text flex-grow-1">{{ app.description }}</p>
                <button @click="installApp(app)" class="btn btn-primary mt-3">
                  安装
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center mt-5">
        <button class="btn btn-secondary" @click="toggleExperienceApps">
          {{ showExperienceApps ? '收起尝鲜版' : '展开尝鲜版' }}
        </button>
      </div>


      <div v-if="showExperienceApps" class="mt-4">
        <div class="row row-cols-1 row-cols-md-2 g-4">
          <div class="col" v-for="app in experienceApps" :key="app.id">
            <div class="card h-100 shadow-sm position-relative">
              <div class="card-body d-flex flex-column">
                <div class="text-center mb-3">
                  <img
                    :src="app.iconSrc"
                    :alt="app.name"
                    class="img-fluid mb-3"
                    style="width: 100px; height: 100px; object-fit: cover;"
                  />
                  <h5 class="card-title">{{ app.name }}</h5>
                  <div class="product-notes" v-if="app.note">{{ app.note }}</div>
                </div>
                <p class="card-text flex-grow-1">{{ app.description }}</p>
                <button @click="installApp(app)" class="btn btn-primary mt-3">
                  安装
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue'
  import { useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import cookie from 'js-cookie'
  
  const router = useRouter()
  const store = useStore()
  const udid = ref('')
  const showAlternativeApps = ref(false)
  const showExperienceApps = ref(false)
  
  onMounted(() => {
    // 直接从 Vuex 或 Cookie 中获取 udid
    udid.value = store.state.udid || cookie.get('udid')
  })
  
  const apps = ref([
    {
      id: 'OfficialWeChat',
      name: '定制微官方替换版',
      description: '安装此版本之前需要卸载官方版本，官替版有消息通知的推送。',
      iconSrc: require('@/assets/WeChat.png'),
      note: '需卸载官方微信',
    },
    {
      id: 'DoppelgangerWeChat',
      name: '定制微分身版【独家推送技术】',
      description:
        '此版本可与官方版共存，适合需要多个微信账号的用户。该版本也具有消息推送功能，无需常驻后台运行。',
      iconSrc: require('@/assets/WeChat.png'),
      note: '分身版可共存',
    },
    {
      id: 'DoppelgangerWeChatAlternative3',
      name: '分身版登录闪退安装我【闪退1 仅微信助手】',
      description: '此版本用于解决分身版登录时闪退的问题，安装此版本登录账号，再安装回上一个分身版即可使用【覆盖安装不要卸载】',
      iconSrc: require('@/assets/WeChat.png'),
      note: '登录闪退用我',
    },
  ])
  
  // 新增备选应用列表
  const alternativeApps = ref([
    {
      id: 'OfficialWeChatAlternative',
      name: '定制微官方替换版【备选1】',
      description: '安装此版本之前需要卸载官方版本，官替版有消息通知的推送。',
      iconSrc: require('@/assets/WeChat.png'),
      note: '备选官替',
    },
    {
      id: 'DoppelgangerWeChatAlternative',
      name: '定制微分身版【备选1】',
      description: '此版本可与官方版共存，适合需要多个微信账号的用户。该版本也具有消息推送功能，无需常驻后台运行。',
      iconSrc: require('@/assets/WeChat.png'),
      note: '备选分身',
    },
    {
      id: 'DoppelgangerWeChatAlternative3',
      name: '分身版登录闪退安装我【闪退2 仅微信助手】',
      description: '此版本用于解决分身版登录时闪退的问题，安装此版本登录账号，再安装回上一个分身版即可使用【覆盖安装不要卸载】',
      iconSrc: require('@/assets/WeChat.png'),
      note: '登录闪退用我',
    },
  ])

  // 新增体验版
  const experienceApps = ref([
    {
      id: 'OfficialWeChatExperience',
      name: '定制微官方替换版【尝鲜8.0.52】',
      description: '已知问题：黄白助手朋友圈功能开启即闪退，可用其他插件的朋友圈功能',
      iconSrc: require('@/assets/WeChat.png'),
      note: '8.0.52',
    },
    {
      id: 'DoppelgangerWeChatExperience',
      name: '定制微分身版【尝鲜8.0.52】',
      description: '已知问题：黄白助手朋友圈功能开启即闪退，可用其他插件的朋友圈功能',
      iconSrc: require('@/assets/WeChat.png'),
      note: '8.0.52',
    },
  ])
  
  const installApp = (app) => {
    if (!udid.value) {
      alert('未找到UDID，请重新获取。')
      router.push({ name: 'UDIDForm' })
      return
    }
  
    router.push({
      name: 'SignPage',
      params: { id: app.id },
      query: {
        appName: app.name,
        iconSrc: app.iconSrc,
      },
    })
  }
  
  // 切换备选应用列表的显示状态
  const toggleAlternativeApps = () => {
    showAlternativeApps.value = !showAlternativeApps.value
  }

  const toggleExperienceApps = () => {
    showExperienceApps.value = !showExperienceApps.value
  }
  </script>
  
  <style scoped>
  .card {
    transition: transform 0.2s;
  }
  
  .card:hover {
    transform: translateY(-5px);
  }
  
  .product-notes {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.25rem 0.5rem;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
    border-radius: 0.25rem;
    color: #721c24;
    font-size: 12px;
  }
  
  .card.position-relative {
    position: relative;
  }
  </style>
